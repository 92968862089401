import { template as template_42649c4c8e0c4864bf696614aac915d7 } from "@ember/template-compiler";
const FKText = template_42649c4c8e0c4864bf696614aac915d7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
