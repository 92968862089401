import { template as template_d7db0022ba444fee8feea44d63ca027c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_d7db0022ba444fee8feea44d63ca027c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
