import { template as template_8ed51d8aed7145059768b0eba5f15364 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ed51d8aed7145059768b0eba5f15364(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
